<template>
  <v-layout wrap class="shop-box ml-2 mr-2" :style="isMobile ? {'min-height': '400px'} : { 'min-height': '400px'}">
    <v-flex xs12 class="shop-item">
      <v-carousel
        v-model="active"
        class="relative"
        :id="'row-'+ parentIndex +'-'+ index"
        :hide-delimiter-background="true" 
        :hide-delimiters="true"
        :show-arrows="isMobile ? false : true"
        :height="isMobile ? 'auto' : 'auto'" 
        :style="isMobile ? item.thumbnail === 'BIG' ? 'min-height:530px' : 'min-height:260px' : 'min-height:400px'"
        style="background-color:#fafafa;border-radius: 10px 10px 0 0;"
      >
        <v-carousel-item v-for="(image, ind) in item.url_images" :key="ind">
          <v-row v-if="!isMobile" class="fill-height" align="center" justify="center" @click="detail(item)">
            <ImageItem :source="image" :alternate="item.product_code" customStyle="border-radius: 10px 10px 0 0;"></ImageItem>
          </v-row>

          <div v-else @click="detail(item)">
            <ImageItem :source="image" :alternate="item.product_code"></ImageItem>
          </div>
        </v-carousel-item>

        <ProductLabel :item="item" :is-mobile="isMobile" :sold-color="soldColor" />
        
        <Countdown v-if="item && item.countdown" class="countdown" :date="getTime(item.end_at)" :isMobile="isMobile" @finish="item.countdown = false"/>
      </v-carousel>
    </v-flex>

    <v-flex xs12 v-if="isMobile" class="center">
      <SliderDelimeter :url_images="item.url_images" :active="active" :pointer-color="pointerColor" />
    </v-flex>

    <v-flex xs12 class="center pointer" style="min-height:110px" @click="detail(item)">
      <div class="mt-1">
        <p class="text"> {{ item.product_name }}</p>
        <p v-if="item.harga_coret && item.harga_coret !== '0'" class="text-through"> {{ addComma(item.harga_coret) }} IDR </p>
        <p v-if="item.start_price == '0'" class="text" style="color:#da291c">  {{ item.selling_price === 0 ? '???' : addComma(item.selling_price) + ' IDR' }} </p>
        <p v-if="item.start_price != '0'" class="text" style="color:#da291c">
          {{ addComma(item.start_price) }} IDR -  {{ addComma(item.end_price) }} IDR
        </p>
        <!-- <p class="text text-sold" v-if="item.sold_qty && item.sold_qty !== '0' && brand === '9'"> {{ item.sold_qty}} pcs terjual</p> -->
      </div>

      <ProductVariant :item="item" />

      <!-- <ProductRating :rating="item.rating_star" /> -->
    </v-flex>
  </v-layout>
</template>

<script>
import ImageItem from '@/components/ImageItem'
import Countdown from '@/components/Countdown'
import SliderDelimeter from '@/components/Shop/SliderDelimeter'
import ProductVariant from '@/components/Shop/ProductVariant'
import ProductLabel from '@/components/Shop/ProductLabel'
// import ProductRating from '@/components/Shop/ProductRating'

export default {
  name: 'HomeProductItem',
  components: { ImageItem, Countdown, SliderDelimeter, ProductVariant, ProductLabel},
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parentIndex: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    pointerColor: {
      type: String,
      default: '#000'
    },
    soldColor: {
      type: String,
      default: 'rgb(218,41,28)'
    }
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    detail (item) {
      this.$store.commit('setYIndexHome', ''+ this.parentIndex + '-' + this.index)
      this.toProduct(item)
    },
    getTime (value) {
      let t = value.split(/[- :]/);
      // Apply each element to the Date function
      let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
      return this.ceilValue((new Date(d)).getTime() / 1000)
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.product-item {
  position: relative;
  text-align: center;
}
.shop-box {
  box-shadow: 1px 2px #c0c0c0;
  border-radius: 10px;
  padding: 0;
}
.relative {
  position: relative;
}
.center {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.text{
  font-size: 0.8rem;
}
.text-sold {
  color: #000 !important;
}
.text-through{
  font-size: 0.8rem;
  color: #000000;
  text-decoration: line-through;
}
.countdown {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.v-window-x-transition-enter-active, .v-window-x-reverse-transition-enter-active, .v-window-y-transition-enter-active, .v-window-y-reverse-transition-enter-active, .v-window-x-transition-leave-active, .v-window-x-reverse-transition-leave-active, .v-window-y-transition-leave-active, .v-window-y-reverse-transition-leave-active {
  transition:ease-in 300ms !important;
}
</style>
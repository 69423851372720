<template>
  <div id="home-shop">
    <v-layout wrap v-if="shop && shop.length > 0">
      <v-flex xs12 class="ml-1 mr-1">
        <v-layout wrap>
          <v-flex :class="isMobile ? item.thumbnail === 'BIG' ? 'xs12 sm12 md12 pt-5 pb-5' : 'xs6 sm6 md6 pt-5 pb-5' : 'xs3 sm3 md3 pt-5 pb-5'" v-for="(item, index) in shop" :key="index">
            <HomeProductItem
              :item="item"
              :parent-index="parentindex"
              :index="index" 
              :is-mobile="isMobile" 
              :pointer-color="pointerColor"
              :sold-color="soldColor" />
          </v-flex>
          <v-flex xs12 style="text-align:center;cursor:pointer;" class="mt-5 mb-10">
            <span class="view-all" @click="toCategory()"> View All</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import HomeProductItem from '@/components/Shop/HomeProductItem'

  export default {
    name: 'HomeProduct',
    components: { HomeProductItem },
    props: {
      shop: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      item: {
        type: Object,
        default: ()=> {
          return {}
        }
      },
      category_name: {
        type: String,
        default: ''
      },
      parentindex: {
        type: Number,
        default: null
      }
    },
    computed: {
      ...mapState([
        'isLoading',
        'isMobile',
        'cartNumber',
        'category',
        'productType',
        'yIndexHome',
        'footer'
      ])
    },
    watch: {},
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        sliderProducts: process.env.VUE_APP_SLIDER_HOME_PRODUCT,
        soldColor: process.env.VUE_APP_LABEL_SOLD,
        pointerColor: '',
        delimeters: null
      }
    },
    methods: {
      toCategory () {
        this.$store.commit('setProductType', 'category')
        this.toShopV2(this.item)
      }
    },
    mounted () {
      this.pointerColor = this.footer && this.footer.color.background ? this.footer.color.background : '#000'
    }
  }
</script>

<style scoped>
.view-all {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  box-shadow: 1px 2px #c0c0c0;
  padding: 5px 10px;
}
</style>

<template>
  <div id="category" v-if="category && category.length > 0" class="mb-8">
    <div style="text-align: center;" v-if="header">
      <h2> {{ header }}</h2>
    </div>
    <div style="text-align: center; margin: 0 5%;">
      <v-layout wrap class="ml-1 mr-1 mt-5">
        <v-flex :class="isMobile? 'xs6 sm6 md6 pt-2 pb-2' : 'xs4 sm4 md4 pt-2 pb-2'" v-for="(item, index) in category" :key="index">
          <v-layout wrap @click="toCategory(item.category_name)">
            <v-flex xs12 class="category-item pl-3 pr-3">
              <!-- <img v-bind:src="item.url_image" class="category-img" alt="img"> -->
              <ImageItem :source="item.url_image" :alternate="item.category_name" customClass="category-img"></ImageItem>
              <span :class="isMobile ? 'text-size-m text-center' : 'text-size text-center'" :style="item.font_color ? { color: item.font_color } : 'color: white'"> {{item.category_name }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import ImageItem from '@/components/ImageItem'

  export default {
    name: 'Category',
    components: { ImageItem },
    props: {
      category: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      header: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState([
        'isLoading',
        'isMobile',
        'cartNumber',
        'productType'
      ])
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
      }
    },
    methods: {
      toCategory (item) {
        this.$store.commit('setProductType', 'category')
        this.toShop(item)
      }
    },
    mounted () {
    }
  }
</script>

<style scoped>
  p {
    margin: 0;
    padding: 0;
  }

  .category-item {
    position: relative;
    text-align: center;
  }

  .category-img:hover {
    opacity: 0.5;
  }

  .text-size {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .text-size-m {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .text-center {
    position: absolute;
    z-index: 20;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
  }
</style>

<template>
  <div class="shop-box">
    <div class="shop-item">
      <v-carousel
        v-model="activeIndex"
        :class="isMobile ? 'my-carousel' : 'my-carousel-dekstop'"
        height="auto"
        :id="'row-' + parentindex + '-' + index"
        :hide-delimiter-background="true"
        :hide-delimiters="true"
        :show-arrows="false"
        :touchless="true"
      >
        <v-carousel-item v-for="(image, ind) in item.url_images" :key="ind">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
            @click="detail(item, index)"
          >
            <ImageItem
              class="mt-2"
              :source="image"
              :alternate="item.product_code"
              customStyle="border-radius: 10px 10px 0 0;"
            ></ImageItem>
          </v-row>
        </v-carousel-item>
      </v-carousel>

      <span
        v-if="!item.qty || item.qty === '0'"
        :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify'"
        :style="
          soldColor
            ? { 'background-color': soldColor }
            : { 'background-color': 'rgb(218,41,28)' }
        "
      >
        {{ $t("shop.sold") }}
      </span>

      <span
        v-if="item.product_lable === 'COMING-SOON'"
        :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon'"
      >
        SOON
      </span>

      <span
        v-if="item.product_lable === 'ON-SALE'"
        :class="isMobile ? 'shop-badge-notify-onsale-m' : 'shop-badge-notify-onsale'"
      >
        ON SALE
      </span>

      <Countdown
        v-if="item && item.countdown"
        class="countdown"
        :isMobile="isMobile"
        :date="getTime(item.end_at)"
        @finish="item.countdown = false"
      />

      <div
        v-if="item.category_menu && item.category_menu === 'Preorder'"
        :class="isMobile ? 'preorder-badge' : 'preorder-badge'"
      >
        PreOrder
      </div>

      <v-btn
        fab
        x-small
        class="shop-icon-left"
        color="#121212"
        @click="previous()"
      >
        <v-icon small color="#fff">mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        fab
        x-small
        class="shop-icon-right"
        color="#121212"
        @click="next()"
      >
        <v-icon small color="#fff">mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <div style="text-align:center">
      <v-icon
        v-for="(itemp, indexp) in item.url_images"
        :key="indexp"
        class="mr-1"
        :color="indexp === activeIndex ? '#121212' : '#C0C0C0'"
        size="7"
      >
        mdi-checkbox-blank-circle
      </v-icon>
    </div>

    <div xs12 style="min-height:110px">
      <div
        style="text-align:center;cursor:pointer;"
        @click="detail(item, index)"
      >
        <p class="text">{{ item.product_name }}</p>
        <p
          v-if="item.harga_coret && item.harga_coret !== '0'"
          class="text-through"
        >
          {{ addComma(item.harga_coret) }} IDR
        </p>
        <p v-if="item.start_price == '0'" class="text" style="color: #da291c;">
          {{ item.selling_price === 0 ? '???' : addComma(item.selling_price) + ' IDR' }}
        </p>
        <p v-if="item.start_price != '0'" class="text" style="color: #da291c;">
          {{ addComma(item.start_price) }} IDR -
          {{ addComma(item.end_price) }} IDR
        </p>
      </div>
      <div
        style="text-align:center;cursor:pointer;"
        v-if="
          item.variant &&
            item.variant.length != 0 &&
            !item.countdown &&
            item.product_lable !== 'COMING-SOON'
        "
      >
        <div class="shop-variant">
          <span v-for="(item, i) in item.variant" :key="i">
            <i
              :class="
                item.status_variant === 'sold'
                  ? 'shop-variant-s'
                  : 'shop-variant-a'
              "
            >
              {{ item.ukuran }}
            </i>
          </span>
        </div>
      </div>
      <div
        style="text-align:center;cursor:pointer;"
        v-if="item.is_bundling && item.is_bundling === true"
      >
        <div class="shop-variant">
          <span>
            <i
              :class="
                item.status === 'sold' ? 'shop-variant-s' : 'shop-variant-a'
              "
            >
              BUNDLING
            </i>
          </span>
        </div>
      </div>
      <p
        class="text text-sold"
        align="center"
        v-if="item.sold_qty && item.sold_qty !== '0' && brand === '9'"
      >
        Terjual {{ item.sold_qty }} pcs
      </p>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/ImageItem";
import Countdown from "@/components/Countdown";
import { mapState } from "vuex";

export default {
  name: "ProductCard",
  components: { ImageItem, Countdown },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentindex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      activeIndex: 0,
      soldColor: process.env.VUE_APP_LABEL_SOLD,
    };
  },
  methods: {
    getTime(value) {
      let t = value.split(/[- :]/);
      // Apply each element to the Date function
      let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return this.ceilValue(new Date(d).getTime() / 1000);
    },
    detail(item, index) {
      this.$store.commit("setYIndexHome", "" + this.parentindex + "-" + index);
      this.toProduct(item);
    },
    next() {
      if (this.activeIndex === this.item.url_images.length - 1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex += 1;
      }
    },
    previous() {
      if (this.activeIndex === 0) {
        this.activeIndex = this.item.url_images.length - 1;
      } else {
        this.activeIndex -= 1;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}

.my-carousel {
  background-color: #fafafa;
  border-radius: 10px 10px 0 0;
  min-height: 375px;
}

.my-carousel-dekstop {
  background-color: #fafafa;
  border-radius: 10px 10px 0 0;
  min-height: 650px;
}

.shop-box {
  box-shadow: 1px 2px #c0c0c0;
  border-radius: 10px;
  padding: 0;
}

.shop-item {
  position: relative;
  text-align: center;
}

.shop-icon-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0.4;
  z-index: 3;
}

.shop-icon-left {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  opacity: 0.4;
  z-index: 3;
}

.shop-badge-notify{
  background-color: rgb(218, 41, 28);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 3%;
}

.shop-badge-notify-m {
  background-color: rgb(218, 41, 28);
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 6%;
}

.shop-badge-notify-soon{
  background-color: #e7a635;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 3%;
}

.shop-badge-notify-soon-m {
  background-color: #e7a635;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 6%;
}

.shop-badge-notify-onsale{
  background-color: rgb(218,41,28);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  right: 3%;
}

.shop-badge-notify-onsale-m {
  background-color: rgb(218,41,28);
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  right: 6%;
}

.preorder-badge {
  position: absolute;
  bottom: 3%;
  left: 3%;
  background-color: rgba(49, 53, 59, 0.68);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: bold;
}

.shop-img {
  max-width: 100%;
  height: auto;
  /* width: 450px;
    height: 450px;
    object-fit: cover; */
  cursor: pointer;
}

.shop-img2 {
  max-width: 100%;
  height: auto;
  /* height: 750px;
    object-fit: cover; */
  cursor: pointer;
}

.shop-img:hover {
  opacity: 0.5;
}

.shop-img-empty {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shop-img-m {
  max-width: 100%;
  height: auto;
}

.shop-img-m2 {
  max-width: 100%;
  height: auto;
}

.text {
  font-size: 0.8rem;
}

.text-sold {
  color: #808080 !important;
  /* font-weight: bold; */
}

.text-through {
  font-size: 0.8rem;
  color: #000000;
  text-decoration: line-through;
}

.countdown {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shop-variant {
  font-size: 0.8rem;
}

.shop-variant-s {
  color: #d3d3d3;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}

.shop-variant-a {
  color: #000;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
}

.v-window-x-transition-enter-active,
.v-window-x-reverse-transition-enter-active,
.v-window-y-transition-enter-active,
.v-window-y-reverse-transition-enter-active,
.v-window-x-transition-leave-active,
.v-window-x-reverse-transition-leave-active,
.v-window-y-transition-leave-active,
.v-window-y-reverse-transition-leave-active {
  transition: ease-in 500ms !important;
}
</style>

<template>
  <div class="home-slider mt-4">
    <div id="slider" ref="slider" class="home-slider-content pb-4">
      <div class="home-slider-item" v-for="(item, index) in shop" :key="index">
        <div
          :class="
            isMobile
              ? 'home-slider-item-content'
              : 'home-slider-item-content-dekstop'
          "
        >
          <ProductCard :item="item" :parentindex="parentindex" :index="index" />
        </div>
      </div>
      <div class="home-slider-item">
        <div
          :class="
            isMobile
              ? 'home-slider-item-content'
              : 'home-slider-item-content-dekstop'
          "
        >
          <span
            :class="
              isMobile
                ? 'home-slider-item-view-all'
                : 'home-slider-item-view-all-dekstop'
            "
            @click="toCategory()"
          >
            View All</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/HomeSlider/ProductCard";
import { mapState } from "vuex";

export default {
  name: "HomeSlider",
  components: { ProductCard },
  props: {
    shop: {
      type: Array,
      default: () => {
        return [];
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentindex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      delimeters: null,
    };
  },
  methods: {
    toCategory() {
      this.$store.commit("setProductType", "category");
      this.toShopV2(this.item);
    },
  },
};
</script>

<style scoped>
.home-slider {
  width: 100%;
  position: relative;
}

.home-slider-content {
  max-width: 100%;
  /* min-height: 430px; */
  display: flex;
  overflow-x: auto;
}

.home-slider-item {
  position: relative;
  cursor: pointer;
  min-height: 55px;
  margin: 0 4px;
}

.home-slider-item-content {
  position: relative;
  width: 225px;
  min-height: 500px;
  padding: 4px;
}

.home-slider-item-content-dekstop{
  position: relative;
  width: 400px;
  min-height: 650px;
  padding: 4px;
}

.home-slider-item-view-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #c0c0c0;
  /* padding: 5px 10px; */
  padding: 12px;
  width: 50%;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}

.home-slider-item-view-all-dekstop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #c0c0c0;
  padding: 24px;
  width: 35%;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.home-slider-item:last-child {
  margin-right: 0px;
}
.home-slider-item:first-child {
  margin-left: 0px;
}


  /* Scroll bar */

  ::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
  }
</style>
